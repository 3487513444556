import { supabase } from '../supabase';
import { Job } from '@/types/job';
import { handleApiError } from '../utils/api-helpers';

interface FetchJobsParams {
  searchQuery?: string;
  filters?: {
    types?: string[];
    levels?: string[];
    locations?: string[];
    salary?: {
      min: number;
      max: number;
    };
    remote?: boolean;
  };
}

export async function fetchJobs(params: FetchJobsParams = {}): Promise<Job[]> {
  try {
    let query = supabase
      .from('jobs')
      .select(`
        *,
        company:companies (
          id,
          name,
          logo,
          industry,
          size,
          location,
          description,
          website,
          verified
        )
      `)
      .eq('status', 'published')
      .order('posted_at', { ascending: false });

    if (params.searchQuery) {
      query = query.or(`
        title.ilike.%${params.searchQuery}%,
        description.ilike.%${params.searchQuery}%
      `);
    }

    if (params.filters) {
      if (params.filters.types?.length) {
        query = query.in('type', params.filters.types);
      }

      if (params.filters.levels?.length) {
        query = query.in('level', params.filters.levels);
      }

      if (params.filters.locations?.length) {
        query = query.in('location', params.filters.locations);
      }

      if (params.filters.salary) {
        query = query
          .gte('salary->min', params.filters.salary.min)
          .lte('salary->max', params.filters.salary.max);
      }

      if (params.filters.remote) {
        query = query.eq('remote', true);
      }
    }

    const { data, error } = await query;

    if (error) throw error;
    return data as Job[];
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw handleApiError(error);
  }
}

export async function fetchJobById(id: string): Promise<Job> {
  try {
    const { data, error } = await supabase
      .from('jobs')
      .select(`
        *,
        company:companies (
          id,
          name,
          logo,
          industry,
          size,
          location,
          description,
          website,
          verified
        )
      `)
      .eq('id', id)
      .single();

    if (error) throw error;
    return data as Job;
  } catch (error) {
    console.error('Error fetching job:', error);
    throw handleApiError(error);
  }
}