import { create } from 'zustand';
import { Job } from '@/types/job';
import { fetchJobs } from '@/lib/api/jobs';

export interface JobsState {
  jobs: Job[];
  loading: boolean;
  error: Error | null;
  filters: {
    types: string[];
    levels: string[];
    locations: string[];
    salary: {
      min: number;
      max: number;
    };
    remote: boolean;
  };
  fetchJobs: () => Promise<void>;
  updateFilters: (filters: Partial<JobsState['filters']>) => void;
}

export const useJobsStore = create<JobsState>((set, get) => ({
  jobs: [],
  loading: false,
  error: null,
  filters: {
    types: [],
    levels: [],
    locations: [],
    salary: {
      min: 0,
      max: 200000,
    },
    remote: false,
  },
  fetchJobs: async () => {
    try {
      set({ loading: true, error: null });
      const jobs = await fetchJobs({ filters: get().filters });
      set({ jobs, loading: false });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },
  updateFilters: (newFilters) => {
    set((state) => ({
      filters: { ...state.filters, ...newFilters },
    }));
    get().fetchJobs();
  },
}));