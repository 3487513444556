import { User, Settings, Shield, HelpCircle } from 'lucide-react';
import type { MenuItem } from './types';

export const menuItems: MenuItem[] = [
  {
    label: 'View Profile',
    icon: User,
    href: '/profile',
  },
  {
    label: 'Settings',
    icon: Settings,
    href: '/settings',
  },
  {
    label: 'Privacy & Security',
    icon: Shield,
    href: '/privacy',
  },
  {
    label: 'Help & Support',
    icon: HelpCircle,
    href: '/help',
  },
];