import { Edit, Trash } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { formatDate } from '@/lib/utils';
import type { Experience } from '@/types/profile';

interface ExperienceItemProps {
  experience: Experience;
  isEditable?: boolean;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
}

export function ExperienceItem({ experience, isEditable, onEdit, onDelete }: ExperienceItemProps) {
  return (
    <div className="flex gap-4">
      <div className="h-12 w-12 flex-shrink-0 rounded-lg bg-gray-100" />
      <div className="flex-1">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="font-medium">{experience.title}</h3>
            <p className="text-gray-600">{experience.company}</p>
            <p className="text-sm text-gray-500">
              {formatDate(new Date(experience.startDate))} -{' '}
              {experience.current ? 'Present' : experience.endDate && formatDate(new Date(experience.endDate))}
            </p>
            <p className="text-sm text-gray-500">{experience.location}</p>
          </div>
          {isEditable && (
            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => onEdit?.(experience.id)}
              >
                <Edit className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => onDelete?.(experience.id)}
              >
                <Trash className="h-4 w-4" />
              </Button>
            </div>
          )}
        </div>
        {experience.description && (
          <p className="mt-2 text-gray-600">{experience.description}</p>
        )}
      </div>
    </div>
  );
}