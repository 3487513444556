import { Card } from '@/components/ui/card';
import { trendingTopics, topContributors } from '@/data/forum-topics';
import type { NewsFeedSidebarProps } from './types';

export function NewsFeedSidebar({ className }: NewsFeedSidebarProps = {}) {
  return (
    <aside className={className}>
      <Card className="p-6">
        <h2 className="text-lg font-semibold">Trending Topics</h2>
        <div className="mt-4 space-y-3">
          {trendingTopics.slice(0, 5).map((topic, index) => (
            <div key={index} className="flex items-center gap-3">
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-100 text-blue-600">
                #{index + 1}
              </div>
              <div>
                <p className="font-medium text-gray-900 dark:text-gray-100">{topic.name}</p>
                <p className="text-sm text-gray-500">{topic.posts} posts</p>
              </div>
            </div>
          ))}
        </div>
      </Card>

      <Card className="mt-6 p-6">
        <h2 className="text-lg font-semibold">Top Contributors</h2>
        <div className="mt-4 space-y-4">
          {topContributors.map((contributor) => (
            <div key={contributor.name} className="flex items-center gap-3">
              <img
                src={contributor.avatar}
                alt={contributor.name}
                className="h-10 w-10 rounded-full object-cover"
              />
              <div>
                <p className="font-medium text-gray-900 dark:text-gray-100">{contributor.name}</p>
                <p className="text-sm text-gray-500">{contributor.contributions} contributions</p>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </aside>
  );
}