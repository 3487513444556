import { useState } from 'react';
import { X } from 'lucide-react';
import { Button } from '../ui/button';

interface SkillsSectionProps {
  skills: string[];
  isEditable?: boolean;
  onAdd?: (skill: string) => void;
  onRemove?: (skill: string) => void;
}

export function SkillsSection({
  skills,
  isEditable,
  onAdd,
  onRemove,
}: SkillsSectionProps) {
  const [newSkill, setNewSkill] = useState('');

  const handleAddSkill = () => {
    if (newSkill.trim() && onAdd) {
      onAdd(newSkill.trim());
      setNewSkill('');
    }
  };

  return (
    <div className="rounded-lg border bg-white p-6 shadow-sm">
      <h2 className="text-xl font-semibold">Skills</h2>

      {isEditable && (
        <div className="mt-4 flex gap-2">
          <input
            type="text"
            value={newSkill}
            onChange={(e) => setNewSkill(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleAddSkill()}
            placeholder="Add a skill..."
            className="flex-1 rounded-lg border p-2 focus:border-primary focus:outline-none focus:ring-1"
          />
          <Button onClick={handleAddSkill}>Add</Button>
        </div>
      )}

      <div className="mt-4 flex flex-wrap gap-2">
        {skills.map((skill) => (
          <span
            key={skill}
            className="flex items-center gap-1 rounded-full bg-gray-100 px-3 py-1 text-sm"
          >
            {skill}
            {isEditable && (
              <button
                onClick={() => onRemove?.(skill)}
                className="ml-1 rounded-full hover:text-red-600"
              >
                <X size={14} />
              </button>
            )}
          </span>
        ))}
        {skills.length === 0 && (
          <p className="text-sm text-gray-500">No skills added yet</p>
        )}
      </div>
    </div>
  );
}