import { MessageSquare, Briefcase, Code, Users, Rocket } from 'lucide-react';
import type { ForumTopic } from '@/types/forum';

export const forumTopics: ForumTopic[] = [
  {
    id: '1',
    title: 'Best Practices for Fine-tuning LLMs',
    description: 'Looking for advice on fine-tuning GPT models for medical text analysis...',
    author: {
      name: 'Dr. Sarah Chen',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
    },
    category: 'llm',
    tags: ['fine-tuning', 'medical-ai', 'gpt'],
    replies: 12,
    likes: 45,
    lastActivity: '2 hours ago',
  },
  {
    id: '2',
    title: 'Implementing Real-time Object Detection on Edge',
    description: 'Currently testing YOLO v8 on Raspberry Pi 4 but facing latency issues...',
    author: {
      name: 'Michael Wong',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400',
    },
    category: 'computer-vision',
    tags: ['edge-ai', 'yolo', 'optimization'],
    replies: 8,
    likes: 32,
    lastActivity: '5 hours ago',
  },
  {
    id: '3',
    title: 'Ethics in AI: Bias in Large Language Models',
    description: 'Discussion on identifying and mitigating bias in LLMs...',
    author: {
      name: 'Emily Johnson',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
    },
    category: 'ethics',
    tags: ['bias', 'llm', 'ethics'],
    replies: 25,
    likes: 67,
    lastActivity: '1 day ago',
  }
];

export const trendingTopics = [
  { rank: 1, name: 'GPT-5', posts: 1234 },
  { rank: 2, name: 'AI Ethics', posts: 856 },
  { rank: 3, name: 'Machine Learning', posts: 642 },
  { rank: 4, name: 'Computer Vision', posts: 531 },
  { rank: 5, name: 'Neural Networks', posts: 423 },
];

export const topContributors = [
  {
    name: 'Sarah Chen',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
    contributions: 156,
  },
  {
    name: 'Michael Wong',
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400',
    contributions: 143,
  },
  {
    name: 'Emily Johnson',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
    contributions: 128,
  },
];

export const categories = [
  { id: 'prompts', name: 'Prompts', icon: MessageSquare },
  { id: 'news', name: 'News', icon: Briefcase },
  { id: 'tutorials', name: 'Tutorials', icon: Code },
  { id: 'experiences', name: 'Experiences', icon: Users },
  { id: 'updates', name: 'Updates', icon: Rocket }
];