import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import type { Experience } from '@/types/profile';

const experienceSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  company: z.string().min(1, 'Company is required'),
  location: z.string().min(1, 'Location is required'),
  startDate: z.string(),
  endDate: z.string().optional(),
  current: z.boolean(),
  description: z.string().optional(),
});

type ExperienceFormData = z.infer<typeof experienceSchema>;

interface ExperienceFormProps {
  experience?: Experience;
  onSubmit: (data: ExperienceFormData) => void;
  onCancel: () => void;
}

export function ExperienceForm({ experience, onSubmit, onCancel }: ExperienceFormProps) {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<ExperienceFormData>({
    resolver: zodResolver(experienceSchema),
    defaultValues: experience || {
      current: false,
    },
  });

  const isCurrent = watch('current');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Title</label>
        <input
          {...register('title')}
          className="mt-1 w-full rounded-md border p-2"
          placeholder="e.g. Senior Software Engineer"
        />
        {errors.title && (
          <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Company</label>
        <input
          {...register('company')}
          className="mt-1 w-full rounded-md border p-2"
          placeholder="e.g. TechCorp Inc."
        />
        {errors.company && (
          <p className="mt-1 text-sm text-red-600">{errors.company.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Location</label>
        <input
          {...register('location')}
          className="mt-1 w-full rounded-md border p-2"
          placeholder="e.g. San Francisco, CA"
        />
        {errors.location && (
          <p className="mt-1 text-sm text-red-600">{errors.location.message}</p>
        )}
      </div>

      <div className="grid gap-4 sm:grid-cols-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">Start Date</label>
          <input
            type="date"
            {...register('startDate')}
            className="mt-1 w-full rounded-md border p-2"
          />
        </div>

        {!isCurrent && (
          <div>
            <label className="block text-sm font-medium text-gray-700">End Date</label>
            <input
              type="date"
              {...register('endDate')}
              className="mt-1 w-full rounded-md border p-2"
            />
          </div>
        )}
      </div>

      <label className="flex items-center gap-2">
        <input type="checkbox" {...register('current')} />
        <span className="text-sm">I currently work here</span>
      </label>

      <div>
        <label className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          {...register('description')}
          rows={4}
          className="mt-1 w-full rounded-md border p-2"
          placeholder="Describe your role and achievements..."
        />
      </div>

      <div className="flex justify-end gap-4">
        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">
          {experience ? 'Update' : 'Add'} Experience
        </Button>
      </div>
    </form>
  );
}