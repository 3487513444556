import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOut } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuthStore } from '@/lib/store';
import { supabase } from '@/lib/supabase';
import { menuItems } from './constants';
import type { ProfileDropdownProps } from './types';
import { useClickOutside } from '@/lib/hooks/use-click-outside';
import { MenuItem } from './menu-item';

export function ProfileDropdown({ className }: ProfileDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { user, setUser } = useAuthStore();

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      setUser(null);
      navigate('/');
      setIsOpen(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!user) {
    return (
      <Button variant="primary" onClick={() => navigate('/auth')}>
        Sign In
      </Button>
    );
  }

  return (
    <div className={className} ref={dropdownRef}>
      <Button
        variant="ghost"
        size="sm"
        className="flex items-center gap-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={user.avatar_url || 'https://via.placeholder.com/32'}
          alt={user.full_name}
          className="h-8 w-8 rounded-full object-cover"
        />
        <span className="hidden md:inline">{user.full_name}</span>
      </Button>

      {isOpen && (
        <div className="absolute right-0 top-full mt-2 w-56 rounded-lg border bg-white p-2 shadow-lg dark:bg-gray-800 dark:border-gray-700">
          <div className="border-b border-gray-200 dark:border-gray-700 p-3">
            <p className="font-medium dark:text-white">{user.full_name}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">{user.email}</p>
          </div>

          <div className="space-y-1 p-1">
            {menuItems.map((item) => (
              <MenuItem
                key={item.href}
                item={item}
                onClick={() => setIsOpen(false)}
              />
            ))}

            <button
              onClick={handleSignOut}
              className="flex w-full items-center gap-2 rounded-md px-3 py-2 text-sm text-red-600 hover:bg-red-50 dark:hover:bg-red-900/10"
            >
              <LogOut className="h-4 w-4" />
              Sign Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
}