import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Profile } from '@/lib/supabase';
import { getProfile } from '@/lib/api/profiles';
import { useAuthStore } from '@/lib/store';
import { ProfileHeader } from '@/components/profile/profile-header';
import { ExperienceSection } from '@/components/profile/experience';
import { SkillsSection } from '@/components/profile/skills-section';
import { ActivityFeed } from '@/components/profile/activity-feed';
import { PostForm } from '@/components/social/post-form';

export function ProfilePage() {
  const { username } = useParams();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const currentUser = useAuthStore((state) => state.user);
  const isOwnProfile = currentUser?.username === username;

  useEffect(() => {
    async function loadProfile() {
      if (!username) return;
      
      try {
        setLoading(true);
        const data = await getProfile(username);
        setProfile(data);
      } catch (error) {
        console.error('Error loading profile:', error);
      } finally {
        setLoading(false);
      }
    }

    loadProfile();
  }, [username]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="container mx-auto px-4 py-8">
          <div className="animate-pulse space-y-8">
            <div className="h-48 rounded-lg bg-gray-200" />
            <div className="h-32 rounded-lg bg-gray-200" />
          </div>
        </div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="container mx-auto px-4 py-8">
          <div className="rounded-lg border bg-white p-8 text-center">
            <h1 className="text-2xl font-bold">Profile not found</h1>
            <p className="mt-2 text-gray-600">The requested profile could not be found.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="container mx-auto px-4 py-8">
        <ProfileHeader profile={profile} isOwnProfile={isOwnProfile} />

        <div className="mt-6 grid gap-6 lg:grid-cols-[2fr_1fr]">
          <div className="space-y-6">
            {isOwnProfile && <PostForm />}
            
            <ExperienceSection
              experiences={profile.experience || []}
              isEditable={isOwnProfile}
            />

            <SkillsSection
              skills={profile.skills || []}
              isEditable={isOwnProfile}
            />
          </div>

          <aside className="space-y-6">
            <ActivityFeed userId={profile.id} />
          </aside>
        </div>
      </div>
    </div>
  );
}