import { supabase } from '../supabase';

interface CreatePostData {
  content: string;
  mediaUrls?: string[];
}

export async function createPost(data: CreatePostData) {
  const { error } = await supabase
    .from('posts')
    .insert({
      content: data.content,
      media_urls: data.mediaUrls || [],
      user_id: (await supabase.auth.getUser()).data.user?.id,
    });

  if (error) throw error;
}

export async function getPosts() {
  const { data, error } = await supabase
    .from('posts')
    .select(`
      *,
      profiles:user_id (
        full_name,
        avatar_url,
        headline
      ),
      likes:likes(count),
      comments:comments(count)
    `)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

export async function likePost(postId: string) {
  const { error } = await supabase
    .from('likes')
    .insert({
      post_id: postId,
      user_id: (await supabase.auth.getUser()).data.user?.id,
    });

  if (error) throw error;
}

export async function createConnection(addresseeId: string) {
  const { error } = await supabase
    .from('connections')
    .insert({
      requester_id: (await supabase.auth.getUser()).data.user?.id,
      addressee_id: addresseeId,
    });

  if (error) throw error;
}

export async function updateConnectionStatus(connectionId: string, status: 'accepted' | 'rejected') {
  const { error } = await supabase
    .from('connections')
    .update({ status })
    .eq('id', connectionId);

  if (error) throw error;
}