import { Brain, Sparkles, Bot, Cpu, Network, Users, MessageSquare, Rocket, Globe, Lock, Zap, Code } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { NeuralBackground } from '@/components/shared/neural-background';

const stats = [
  { label: 'Active AI Professionals', value: '150K+' },
  { label: 'AI Projects Shared', value: '75K+' },
  { label: 'Research Papers Discussed', value: '25K+' },
  { label: 'AI Models Deployed', value: '100K+' },
];

const features = [
  {
    icon: Bot,
    title: 'AI-Powered Matching',
    description: 'Our advanced AI algorithms connect you with the perfect opportunities and collaborators based on your expertise and interests.',
  },
  {
    icon: Network,
    title: 'Intelligent Networking',
    description: 'Build meaningful connections with AI professionals worldwide, enhanced by our smart recommendation system.',
  },
  {
    icon: Cpu,
    title: 'AI Project Showcase',
    description: 'Share and discover cutting-edge AI projects, with automated analysis and insights powered by our AI platform.',
  },
  {
    icon: MessageSquare,
    title: 'Expert Community',
    description: 'Engage with a vibrant community of AI experts, researchers, and practitioners in meaningful discussions.',
  },
  {
    icon: Lock,
    title: 'Secure Collaboration',
    description: 'Enterprise-grade security ensures your intellectual property and sensitive information remain protected.',
  },
  {
    icon: Rocket,
    title: 'Career Growth',
    description: 'Access exclusive AI job opportunities and accelerate your career growth with personalized recommendations.',
  },
];

export function HomePage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white">
      <NeuralBackground />
      
      {/* Hero Section */}
      <section className="relative overflow-hidden py-20">
        <div className="container mx-auto px-4">
          <div className="mx-auto max-w-4xl text-center">
            <div className="mb-8 flex justify-center">
              <div className="rounded-full bg-blue-500/20 p-4 backdrop-blur-lg">
                <Brain className="h-16 w-16" />
              </div>
            </div>
            <h1 className="mb-6 text-4xl font-bold sm:text-6xl">
              Welcome to Connectia
              <span className="relative ml-2 block text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
                The Future of AI Innovation
              </span>
            </h1>
            <p className="mb-8 text-lg text-blue-100 sm:text-xl">
              Join the elite network of AI professionals where cutting-edge algorithms power every interaction,
              from job matching to knowledge sharing.
            </p>
            <div className="flex flex-col gap-4 sm:flex-row sm:justify-center">
              <Button
                size="lg"
                className="bg-white text-blue-900 hover:bg-blue-50"
                href="/auth"
              >
                <Sparkles className="mr-2 h-5 w-5" />
                Get Started
              </Button>
              <Button
                size="lg"
                variant="ghost"
                className="border border-white text-white hover:bg-blue-800"
                href="/explore"
              >
                Explore Projects
                <Rocket className="ml-2 h-5 w-5" />
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-16">
        <div className="container">
          <div className="grid grid-cols-2 gap-8 sm:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.label} className="text-center">
                <div className="text-3xl font-bold text-blue-400">{stat.value}</div>
                <div className="mt-2 text-sm text-gray-300">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="relative py-20">
        <div className="container">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold sm:text-4xl">
              Powered by Advanced AI
            </h2>
            <p className="mt-4 text-gray-300">
              Our platform leverages state-of-the-art artificial intelligence to enhance every aspect of your professional journey.
            </p>
          </div>
          <div className="mt-16 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div
                key={feature.title}
                className="group rounded-lg bg-gray-800/50 p-8 backdrop-blur-sm transition-all hover:-translate-y-1 hover:bg-gray-800/70"
              >
                <feature.icon className="mb-4 h-12 w-12 text-blue-400 transition-transform group-hover:scale-110" />
                <h3 className="mb-2 text-xl font-semibold">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative py-20">
        <div className="container text-center">
          <h2 className="mb-4 text-3xl font-bold">Ready to Join the Future?</h2>
          <p className="mb-8 text-gray-300">
            Join thousands of AI professionals already benefiting from our intelligent platform.
          </p>
          <Button
            size="lg"
            className="bg-white text-blue-900 hover:bg-blue-50"
            href="/auth"
          >
            <Sparkles className="mr-2 h-5 w-5" />
            Get Started Now
          </Button>
        </div>
      </section>
    </div>
  );
}