import { formatDistanceToNow } from '@/lib/utils';
import type { NewsPostHeaderProps } from './types';

export function NewsPostHeader({ title, author, createdAt }: NewsPostHeaderProps) {
  return (
    <div className="flex items-start gap-4">
      <img
        src={author.avatar}
        alt={author.name}
        className="h-12 w-12 rounded-full object-cover"
      />
      <div>
        <h1 className="text-xl font-semibold dark:text-white">{title}</h1>
        <div className="mt-1">
          <span className="font-medium dark:text-gray-200">{author.name}</span>
          <span className="text-sm text-gray-500 dark:text-gray-400"> · {author.headline}</span>
        </div>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          {formatDistanceToNow(new Date(createdAt))}
        </p>
      </div>
    </div>
  );
}