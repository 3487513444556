import { useState } from 'react';
import { NewsCategory } from '@/types/news';
import { useNewsStore } from '@/lib/store';
import { CategorySelector } from './category-selector';
import { PostList } from './post-list';
import { NewsFeedSidebar } from './news-feed-sidebar/news-feed-sidebar';
import { CreateNewsPost } from '../../create/create-news-post';
import { filterPostsByCategory } from '../utils/filters';

export function NewsFeed() {
  const [selectedCategory, setSelectedCategory] = useState<NewsCategory>(NewsCategory.ALL);
  const posts = useNewsStore((state) => filterPostsByCategory(state.posts, selectedCategory));

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">Connectia News</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Stay updated with the latest in AI technology and innovation
        </p>
        
        <div className="mt-6">
          <CategorySelector 
            selectedCategory={selectedCategory}
            onCategoryChange={setSelectedCategory}
          />
        </div>
      </div>

      <div className="grid gap-8 lg:grid-cols-[1fr_300px]">
        <main className="space-y-6">
          <CreateNewsPost />
          <PostList posts={posts} />
        </main>

        <NewsFeedSidebar />
      </div>
    </div>
  );
}