import { useState } from 'react';
import { Camera, Edit, MapPin, Briefcase } from 'lucide-react';
import { Button } from '../ui/button';
import { Profile } from '@/lib/supabase';
import { updateProfile } from '@/lib/api/profiles';
import toast from 'react-hot-toast';

interface ProfileHeaderProps {
  profile: Profile;
  isOwnProfile?: boolean;
}

export function ProfileHeader({ profile, isOwnProfile }: ProfileHeaderProps) {
  const [isEditing, setIsEditing] = useState(false);

  const handleUpdateAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      // Handle avatar upload logic here
      toast.success('Profile photo updated');
    } catch (error) {
      toast.error('Failed to update profile photo');
    }
  };

  return (
    <div className="relative rounded-lg border bg-white p-6 shadow-sm">
      <div className="flex flex-col items-center gap-4 sm:flex-row sm:items-start">
        <div className="relative">
          <img
            src={profile.avatar_url || 'https://via.placeholder.com/150'}
            alt={profile.full_name}
            className="h-32 w-32 rounded-full object-cover"
          />
          {isOwnProfile && (
            <label className="absolute bottom-0 right-0 cursor-pointer rounded-full bg-white p-2 shadow-lg hover:bg-gray-50">
              <Camera className="h-5 w-5 text-gray-600" />
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleUpdateAvatar}
              />
            </label>
          )}
        </div>

        <div className="flex-1 text-center sm:text-left">
          <div className="flex items-center justify-center gap-4 sm:justify-start">
            <h1 className="text-2xl font-bold">{profile.full_name}</h1>
            {isOwnProfile && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsEditing(!isEditing)}
              >
                <Edit className="h-4 w-4" />
              </Button>
            )}
          </div>

          {profile.headline && (
            <p className="mt-1 text-lg text-gray-600">{profile.headline}</p>
          )}

          <div className="mt-4 flex flex-wrap items-center justify-center gap-4 text-gray-600 sm:justify-start">
            {profile.location && (
              <div className="flex items-center gap-2">
                <MapPin className="h-5 w-5" />
                <span>{profile.location}</span>
              </div>
            )}
            {profile.current_position && (
              <div className="flex items-center gap-2">
                <Briefcase className="h-5 w-5" />
                <span>{profile.current_position}</span>
              </div>
            )}
          </div>

          {profile.bio && (
            <p className="mt-4 text-gray-600">{profile.bio}</p>
          )}
        </div>
      </div>
    </div>
  );
}