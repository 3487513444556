import { useState } from 'react';
import { Heart, MessageCircle, Share2 } from 'lucide-react';
import { Button } from '../ui/button';
import { formatDistanceToNow } from '@/lib/utils';
import { Post } from '@/types/social';
import { likePost } from '@/lib/api/social';
import toast from 'react-hot-toast';

interface PostCardProps {
  post: Post;
}

export function PostCard({ post }: PostCardProps) {
  const [likes, setLikes] = useState(post.likes_count);
  const [isLiked, setIsLiked] = useState(false);

  const handleLike = async () => {
    try {
      await likePost(post.id);
      setLikes(prev => prev + 1);
      setIsLiked(true);
      toast.success('Post liked!');
    } catch (error) {
      toast.error('Failed to like post');
    }
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success('Link copied to clipboard!');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  return (
    <div className="rounded-lg border bg-white p-6 shadow-sm">
      <div className="flex items-start gap-4">
        <img
          src={post.profiles.avatar_url || 'https://via.placeholder.com/40'}
          alt={post.profiles.full_name}
          className="h-10 w-10 rounded-full object-cover"
        />
        
        <div className="flex-1">
          <div className="flex items-start justify-between">
            <div>
              <h3 className="font-medium">{post.profiles.full_name}</h3>
              {post.profiles.headline && (
                <p className="text-sm text-gray-500">{post.profiles.headline}</p>
              )}
              <p className="text-sm text-gray-500">
                {formatDistanceToNow(new Date(post.created_at))}
              </p>
            </div>
          </div>

          <p className="mt-2 whitespace-pre-wrap text-gray-800">{post.content}</p>

          {post.media_urls?.length > 0 && (
            <div className="mt-4 grid gap-2">
              {post.media_urls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Post media ${index + 1}`}
                  className="rounded-lg"
                />
              ))}
            </div>
          )}

          <div className="mt-4 flex items-center gap-4">
            <Button
              variant="ghost"
              size="sm"
              className={`gap-2 ${isLiked ? 'text-red-500' : ''}`}
              onClick={handleLike}
            >
              <Heart className={isLiked ? 'fill-current' : ''} size={20} />
              <span>{likes}</span>
            </Button>
            
            <Button variant="ghost" size="sm" className="gap-2">
              <MessageCircle size={20} />
              <span>{post.comments_count}</span>
            </Button>
            
            <Button variant="ghost" size="sm" onClick={handleShare}>
              <Share2 size={20} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}