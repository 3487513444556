import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../ui/button';
import { Image, Link, Smile } from 'lucide-react';
import { useAuthStore } from '@/lib/store';
import { createPost } from '@/lib/api/social';
import toast from 'react-hot-toast';

const postSchema = z.object({
  content: z.string().min(1, 'Post content is required'),
  mediaUrls: z.array(z.string().url()).optional(),
});

type PostFormData = z.infer<typeof postSchema>;

export function PostForm() {
  const [isExpanded, setIsExpanded] = useState(false);
  const user = useAuthStore((state) => state.user);
  const [mediaUrls, setMediaUrls] = useState<string[]>([]);

  const { register, handleSubmit, reset, formState: { errors } } = useForm<PostFormData>({
    resolver: zodResolver(postSchema),
  });

  const onSubmit = async (data: PostFormData) => {
    try {
      await createPost({
        content: data.content,
        mediaUrls,
      });
      reset();
      setMediaUrls([]);
      setIsExpanded(false);
      toast.success('Post created successfully!');
    } catch (error) {
      toast.error('Failed to create post');
    }
  };

  if (!user) return null;

  return (
    <div className="rounded-lg border bg-white p-6 shadow-sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-4">
          <img
            src={user.avatar_url || 'https://via.placeholder.com/40'}
            alt={user.full_name}
            className="h-10 w-10 rounded-full object-cover"
          />
          <div className="flex-1">
            <textarea
              {...register('content')}
              placeholder="Share your thoughts..."
              className={`w-full rounded-lg border p-4 focus:border-blue-500 focus:outline-none focus:ring-1 ${
                isExpanded ? 'h-32' : 'h-12'
              }`}
              onClick={() => setIsExpanded(true)}
            />
            {errors.content && (
              <p className="mt-1 text-sm text-red-600">{errors.content.message}</p>
            )}

            {isExpanded && (
              <div className="mt-4 flex items-center justify-between">
                <div className="flex gap-2">
                  <Button type="button" variant="ghost" size="sm">
                    <Image size={20} />
                  </Button>
                  <Button type="button" variant="ghost" size="sm">
                    <Link size={20} />
                  </Button>
                  <Button type="button" variant="ghost" size="sm">
                    <Smile size={20} />
                  </Button>
                </div>
                <div className="flex gap-2">
                  <Button type="button" variant="secondary" onClick={() => setIsExpanded(false)}>
                    Cancel
                  </Button>
                  <Button type="submit">Post</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}