import { useState } from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ExperienceItem } from './experience-item';
import { ExperienceForm } from './experience-form';
import type { Experience } from '@/types/profile';

interface ExperienceSectionProps {
  experiences: Experience[];
  isEditable?: boolean;
  onAdd?: (experience: Omit<Experience, 'id'>) => void;
  onEdit?: (id: string, experience: Partial<Experience>) => void;
  onDelete?: (id: string) => void;
}

export function ExperienceSection({
  experiences,
  isEditable,
  onAdd,
  onEdit,
  onDelete,
}: ExperienceSectionProps) {
  const [isAdding, setIsAdding] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);

  const handleAdd = (data: Omit<Experience, 'id'>) => {
    onAdd?.(data);
    setIsAdding(false);
  };

  const handleEdit = (data: Omit<Experience, 'id'>) => {
    if (editingId) {
      onEdit?.(editingId, data);
      setEditingId(null);
    }
  };

  return (
    <div className="rounded-lg border bg-white p-6">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Experience</h2>
        {isEditable && !isAdding && !editingId && (
          <Button variant="ghost" onClick={() => setIsAdding(true)}>
            <Plus className="mr-2 h-4 w-4" />
            Add Experience
          </Button>
        )}
      </div>

      {(isAdding || editingId) && (
        <div className="mt-6">
          <ExperienceForm
            experience={editingId ? experiences.find(e => e.id === editingId) : undefined}
            onSubmit={editingId ? handleEdit : handleAdd}
            onCancel={() => {
              setIsAdding(false);
              setEditingId(null);
            }}
          />
        </div>
      )}

      <div className="mt-6 space-y-6">
        {experiences.map((experience) => (
          <ExperienceItem
            key={experience.id}
            experience={experience}
            isEditable={isEditable && !isAdding && !editingId}
            onEdit={setEditingId}
            onDelete={onDelete}
          />
        ))}
      </div>
    </div>
  );
}